import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import logo from "../../assets/logoBlanco.svg";
import logo2 from "../../assets/logo.svg";
import fondo from "../../assets/fondo.jpg";
import { auth } from "../../firebaseConfig";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import { OAuthProvider } from "firebase/auth";


export const LoginPage = () => {
  const navigate = useNavigate();

  const userAuth = useSelector((state) => state.userAuth.userInfo);
  useEffect(() => {
    if (userAuth?.email) {
      navigate("/");
    }
  }, [userAuth, navigate]);

  const [load, setLoad] = useState(false);
  const defaultTheme = createTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    try {
      setLoad(true);
      // const provider = new OAuthProvider("microsoft.com");

      await signInWithEmailAndPassword(auth, email.trim(), password);
      // signInWithPopup(auth, provider)
      //   .then((result) => {
      //     //econsole.log(result)
      //     // User is signed in.
      //     // IdP data available in result.additionalUserInfo.profile.

      //     // Get the OAuth access token and ID Token
      //     const credential = OAuthProvider.credentialFromResult(result);
      //     const accessToken = credential.accessToken;
      //     const idToken = credential.idToken;
      //   })
      //   .catch((error) => {
      //     console.error(error)
      //     // Handle error.
      //   });
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        text: "Las credenciales no pertenecen a ningún usuario",
      });
    }
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            backgroundImage: `url(${fondo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid
              item
              px={6}
              py={2}
              sx={{
                backgroundColor: "rgba(0,0,0,.4)",
                color: "#fffff",
                // maxWidth: "500px",
                display: { xs: "none", sm: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
                borderRadius: "25px 0px 0px 25px",
              }}
            >
              <img src={logo} alt="logo" width={200} />
            </Grid>
            <Grid
              item
              px={4}
              xs={12}
              sm={12}
              md={"auto"}
              py={2}
              sx={{
                backgroundColor: "white",
                color: "#fffff",
                height: { xs: "100vh", sm: "100vh", md: "400px" },
                borderRadius: { xs: "0px", sm: "0px", md: "0px 25px 25px 0px" },
                display: { xs: "block", sm: "block", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                px={2}
                py={2}
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={logo2} alt="logo" width={300} />
              </Box>
              <Stack direction={"column"}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  <strong>Iniciar Sesión</strong>
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography>
                    <strong>Correo</strong>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="agrobimsa-email2"
                    name="agrobimsa-email2"
                    autoComplete="agrobimsa-email2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    // autoFocus
                  />
                  <Typography sx={{ mt: 3 }}>
                    <strong>Contraseña</strong>
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    id="agrobimsa-password2"
                    name="agrobimsa-password2"
                    autoComplete="agrobimsa-password2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    fullWidth
                    disabled={
                      email.trim().length === 0 || password.trim().length === 0
                    }
                    onClick={() => handleSubmit()}
                    variant="contained"
                    sx={{ mt: 5, mb: 2 }}
                  >
                    Ingresar
                  </Button>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ mt: 5 }}
                  >
                    <strong>
                      {"Copyright © "}
                      <a
                        color="inherit"
                        href="https://agrobimsa.com/home/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Agrobimsa
                      </a>
                      {" " + new Date().getFullYear() +" v0.0.12"}
                    </strong>
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Cargando open={load} />
        </Box>
      </ThemeProvider>
    </div>
  );
};
