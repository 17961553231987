import moment from "moment";
import { logoF } from "../../components/utils/LogoF";

export const fixWidths = (widths) => {
    let tot = 0, stars = [];
    widths.forEach((w, i) => {
        switch (typeof w) {
            case 'string':
                if (w === '*') {
                    stars.push(i);
                    return;
                }
                w = widths[i] = w.endsWith('%') ?
                    parseFloat(w) * 514.3 / 100 :
                    Number(w);
                if (isNaN(w)) {
                    throw new Error(`Invalid width: ${w}`)
                } else {
                    tot += w;
                }
                break;
            case 'number':
                tot += w;
                break;
        }
    });
    const numStars = stars.length;
    if (numStars) {
        tot = (496.3 - tot) / numStars;
        for (const i of stars)
            widths[i] = tot;
    }
    return widths.map(w => w - 9);
};



export const formatoEstado = (datos) => {
    //econsole.log(datos)
    let historico = datos.tipo3 !== "ACTUAL"
    let lista = datos?.lista ?? []

    function total(campo){
        return lista.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(parseFloat(currentValue[campo]).toFixed(2)),
            0
          ).toFixed(2)
    }
    function total2(campo1, campo2){
        return lista.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(parseFloat(currentValue[campo1] - currentValue[campo2]).toFixed(2)),
            0
          ).toFixed(2)
    }

    return {
        footer: {
            columns: [
              { text: 'Generado el '+moment(datos.consulta ?? new Date()).format("DD/MM/YYYY HH:mm"), alignment: 'left', margin:[60,0,60,0], style:'footer'}
            ]
          },
        content: [
            {
                
                absolutePosition: { x: 257.15, y: 28 },
                width: 80,
                image: logoF,
            },
            { text: `ESTADO DE CLIENTE`, style: 'header', alignment: "center", margin: [0, 60, 0, 5]},
            { text: `${historico ? datos?.fechas : "Corte al: " + datos?.corte}`, style: 'header2', alignment: "center", margin: [0, 0, 0, 5]},
            {
                style: 'table',
                color: '#444',
                margin: [0, 0, 0, 10],
                table: {
                    widths: fixWidths(["15%", "35%", "15%", "35%"]),
                    headerRows: 1,
                    body: [
                        [{ text: datos.CardCode ?? "", style: 'azul', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.CardName ?? "",  style: 'azul', alignment: 'left', border: [true, true, true, true] },
                        { text: "Vendedor:", style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.SlpName ?? "", style: 'table', alignment: 'left', border: [true, true, true, true] },],

                        [{ text: 'División:', style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.DIVISION ?? "" , alignment: 'left', style: 'table', border: [true, true, true, true] },
                        { text: "Plazo:", style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.ExtraDays ?  datos.ExtraDays +" días" :"", style: 'tableBold', alignment: 'left', border: [true, true, true, true] },],

                        [{ text: 'Teléfono #:', style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.Phone1 ?? "",  alignment: 'left', style: 'table', border: [true, true, true, true] },
                        { text: "Contacto:", style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.CntctPrsn ?? "", alignment: 'left', style: 'table', border: [true, true, true, true] },],

                        [{ text: 'Dirección:', rowSpan:2, style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.Address ?? "",  rowSpan:2, alignment: 'left', style: 'table', border: [true, true, true, true] },
                        { text: 'Límite de Crédito:', style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.CreditLine ? "$ " +parseFloat(datos.CreditLine).toFixed(2) : "",  style: 'tableBold', alignment: 'left', border: [true, true, true, true] },
                    ],

                        [{ },
                        { },
                        { text: "Límite Compometido:", style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                        { text: datos.DebtLine ? "$ " +parseFloat(datos.DebtLine).toFixed(2) : "", style: 'tableBold', alignment: 'left', border: [true, true, true, true] },],

                            [{ text: 'Correo:', style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                            { text: datos.E_Mail ?? "", alignment: 'left', style: 'table', border: [true, true, true, true] },
                            { text: 'Saldo Cuenta:', style: 'grisClaro', alignment: 'center', border: [true, true, true, true] },
                            { text: "$ " +total("SALDO"), style: 'tableBold2', alignment: 'left', border: [true, true, true, true], },
                        ],
                    ]
                }
            },

            {
                style: 'table',
                color: '#444',
                table: {
                    widths: ["100%"],
                    body: [
                        [{ text: 'Listado de Facturas', style: 'azul', alignment: 'center', border: [true, true, true, false] },
                        ],
                    ]
                }
            },
            {
                style: 'table',
                color: '#444',
                margin: [0, -0.5, 0, 0],
                table: { 
                    headerRows: 1,
                    widths: fixWidths(historico ? 
                        ["8.9%", "6.775%","6.775%","8.9%","8.5%","6.775%","8.5%","6.775%","0.3%","12.4%","0.3%","12.4%","0.3%","12.4%"]
                        : 
                        ["8%", "5.875%","5.875%","8%","7.6%","5.875%","7.6%","5.875%","0.3%","8.76%","0.3%","8.76%","0.3%","8.76%","0.3%","8.76%","0.3%","8.76%"]),
                    body: [
                       
                        [{
                            text: "F. Emisión", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Plazo", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Días Gracia", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Vence Gracia", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Tipo", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Factura", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Estado", style: 'grisClaro', alignment: 'center'
                        },
                        {
                            text: "Días Vence", style: 'grisClaro', alignment: 'center'
                        },
                        ].concat(
                            historico ?
                                [{
                                    text:  "Débito", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Crédito" , style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Saldo", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {}]
                                :
                                [{
                                    text: "Valor", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Multa Prot.", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Cobrado", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Saldo", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {},
                                {
                                    text: "Retención", style: 'grisClaro', alignment: 'center', colSpan:2
                                },
                                {}]
                        ) ,
                        ...lista.map((val) => {
                            return [
                                
                                {
                                    text: val?.RefDate ? moment(val?.RefDate).format("DD/MM/YYYY"): '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.PLAZO ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.GRACIA ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.DueDate ? moment(val?.DueDate).format("DD/MM/YYYY"): '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.Tipo ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.FolioNum ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.Estado ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                                {
                                    text: val?.DVence ?? '', style: (val?.DVence >0? "error": 'table'), alignment: 'center', border: [true, false, true, true]
                                },
                            ].concat(
                                historico ?
                                [{
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.Debit  ? parseFloat(val?.Debit).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', alignment: 'left', style: (val?.DVence >0? "error": 'table'), border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.Credit  ? parseFloat(val?.Credit).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.SALDO ? parseFloat(val?.SALDO).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                               ] : 
                                [{
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.DocTotal  ? parseFloat(val?.DocTotal).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', alignment: 'left', style: (val?.DVence >0? "error": 'table'), border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.MULTA  ? parseFloat(val?.MULTA).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.PaidToDate  ? (parseFloat(val?.PaidToDate) - parseFloat(val?.Ret ?? 0.00)).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.SALDO ? parseFloat(val?.SALDO).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                },
                                {
                                    text: '$', style: (val?.DVence >0? "error": 'table'), alignment: 'left', border: [true, false, false, true], margin:[-2,0,0,0]
                                },
                                {
                                    text: val?.Ret ? parseFloat(val?.Ret).toFixed(2): '', style: (val?.DVence >0? "error": 'table'), alignment: 'right', border: [false, false, true, true]
                                }]
                            )                                
                        }
                        
                    ),
                    [{
                        text: "Totales:", style: 'grisClaro', alignment: 'right', colSpan:8
                    },
                    {},{},{},{},{},{},{},
                ].concat(historico ?
                        [
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("Debit"), alignment: 'right', style:"tableBold", border: [false, false, true, true],
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("Credit"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("SALDO"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                        ]
                        :
                        [
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("DocTotal"), alignment: 'right', style:"tableBold", border: [false, false, true, true],
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("MULTA"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total2("PaidToDate","Ret"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("SALDO"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                            {
                                text: '$', alignment: 'left', style:"tableBold", border: [true, false, false, true], margin:[-2,0,0,0]
                            },
                            {
                                text: total("Ret"), alignment: 'right', style:"tableBold", border: [false, false, true, true]
                            },
                        ]
                    )
                    ]

                }
            },
        ],
        pageSize: "A4",

        styles: {
            header: {
                fontSize: 18,
                bold: true,
                color: "black"
            },
            header2: {
                fontSize: 10,
                color: "black"
            },
            table: {
                color: "black",
                fontSize: 6,
            },
            error: {
                fontSize: 6,
                color: "black",
                fillColor: "#ffdfd4",
            },
            footer: {
                color: "black",
                fontSize: 9,
            },
            grisClaro: {
                color: "black",
                fillColor: "#d4d3d3",
                fontSize: 6,
                bold: true,
            },
            tableBold: {
                color: "black",
                fontSize: 6,
                bold: true,
            },
            tableBold2: {
                color: "black",
                fontSize: 7,
                bold: true,
            },
            azul: {
                color: "white",
                fillColor: "#0087e6",
                fontSize: 6,
                bold: true,
            },
        },
    }
}