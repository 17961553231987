import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect, useState } from "react";
import { formato } from "./Formato";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  uploadBytes,
  ref as ref_storage,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import { db, storage } from "../../firebaseConfig";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import { Cargando } from "../../components/utils/Cargando";
import SignatureCanvas from "react-signature-canvas";
import { respuesta } from "../../components/utils/Respuesta";
import { useSelector } from "react-redux";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addLog } from "../../components/utils/Log";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const Pdf = ({ datos, cargar }) => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [load, setLoad] = useState(false);

  const [url, setUrl] = useState("");

  useEffect(() => {
    cargarDoc();
  }, [datos]);
  const [base64, setBase64] = useState("");

  async function actualizar(sol) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const raw = JSON.stringify({
      encargadoCompras: datos?.encargadoCompras?.nombre ?? "",
      correoCompras: datos?.encargadoCompras?.correo ?? "",
      telefonoCompras: datos?.encargadoCompras?.telefono ?? "",

      encargadoPagos: datos?.encargadoPagos?.nombre ?? "",
      correoPagos: datos?.encargadoPagos?.correo ?? "",
      telefonoPagos: datos?.encargadoPagos?.telefono ?? "",

      banco1: datos?.rBancaria1?.banco ?? "",
      contacto1: datos?.rBancaria1?.contacto ?? "",
      telefono1: datos?.rBancaria1?.telefono ?? "",

      banco2: datos?.rBancaria2?.banco ?? "",
      contacto2: datos?.rBancaria2?.contacto ?? "",
      telefono2: datos?.rBancaria2?.telefono ?? "",

      rcNombre1: datos?.rComercial1?.nombre ?? "",
      rcContacto1: datos?.rComercial1?.contacto ?? "",
      rcDireccion1: datos?.rComercial1?.direccion ?? "",
      rcProvincia1: datos?.rComercial1?.provincia ?? "",
      rcCredito1: datos?.rComercial1?.credito ?? "",
      rcAntiguedad1: datos?.rComercial1?.antiguedad ?? "",
      rcObservaciones1: datos?.rComercial1?.observaciones ?? "",
      rcSaldo1: datos?.rComercial1?.saldo ?? "",
      rcTelefono1: datos?.rComercial1?.telefono ?? "",
      rcPlazo1: datos?.rComercial1?.plazo ?? "",

      rcNombre2: datos?.rComercial1?.nombre ?? "",
      rcContacto2: datos?.rComercial1?.contacto ?? "",
      rcDireccion2: datos?.rComercial1?.direccion ?? "",
      rcProvincia2: datos?.rComercial1?.provincia ?? "",
      rcCredito2: datos?.rComercial1?.credito ?? "",
      rcAntiguedad2: datos?.rComercial1?.antiguedad ?? "",
      rcObservaciones2: datos?.rComercial1?.observaciones ?? "",
      rcSaldo2: datos?.rComercial1?.saldo ?? "",
      rcTelefono2: datos?.rComercial1?.telefono ?? "",
      rcPlazo2: datos?.rComercial1?.plazo ?? "",

      solicitud: sol,
      status: "Revisión Financiera",
    });

    const requestOptions2 = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let res = null;

    await fetch(
      "https://crm.agrobimsa.com/BACK/api/v1/Lead/" + datos.codigo,
      requestOptions2
    )
      .then(async (response) => {
        return await respuesta(response);
      })
      .then((result) => {
        enviar(sol);
        res = JSON.parse(result)?.id;
      })
      .catch((error) => {
        console.error(error);
      });

    return res;
  }

  function enviar(sol) {
    const functions = getFunctions();
    const sendSolicitud = httpsCallable(functions, "sendSolicitud");

    sendSolicitud({
      correo: userInfo.email,
      solicitud: sol,
      codigo: datos.codigo,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const cargarPDF = async (datos2) => {
    let obj = {};
    if (datos2.solicitud) {
      obj = {
        ...datos2.solicitud,
        ...datos2.datos,
        firmaCliente: datos2.firmaCliente,
        firmaRepresentante: datos2.firmaRepresentante,
      };
    }
    const response = await createPdf(formato(obj), "b64");

    if (!response?.success) {
      Swal.fire({ icon: "error", text: "Error: " + response?.message });
      return;
    }
    setBase64(response?.content ?? "");
  };

  async function cargarDoc() {
    setSign("");
    setLoad(true);

    try {
      setLoad(false);
      if (datos) {
        if (datos.documento) {
          setUrl(datos.documento);
        } else {
          cargarPDF(datos);
        }
      }
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        text:
          "Error: " +
          (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
      });
    }
  }

  const createPdf = async (docDefinition, output = "print") => {
    return new Promise((resolve, reject) => {
      if (output === "b64") {
        const pdfMakeCreatePdf = pdfMake.createPdf(docDefinition);
        pdfMakeCreatePdf.getBase64((data) => {
          resolve({
            success: true,
            content: data,
            message: "Archivo generado correctamente.",
          });
        });
        return;
      }
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.download("Archivo.pdf");
    });
  };

  const changeImagen = (e) => {
    const file = e.target.files[e.target.files.length - 1];
    if (file) {
      if (file.size > 5242880) {
        // 5 MB in bytes
        Swal.fire({
          icon: "error",
          text: "El archivo subido tiene un peso mayor a 5mb",
        });
      } else {
        subirImagen(file, "CedulaFrontal");
      }
    }
  };

  const changeImagen2 = (e) => {
    const file = e.target.files[e.target.files.length - 1];
    if (file) {
      if (file.size > 5242880) {
        // 5 MB in bytes
        Swal.fire({
          icon: "error",
          text: "El archivo subido tiene un peso mayor a 5mb",
        });
      } else {
        subirImagen(file, "CedulaPosterior");
      }
    }
  };

  async function subirImagen(imagen, nombre) {
    setLoad(true);
    let extension = imagen?.name.split(".");
    extension = extension[extension.length - 1];

    try {
      const storageRef = ref_storage(
        storage,
        `Solicitud de Crédito/${
          "SOLICITUD - " + datos.codigo + "/" + nombre + "." + extension
        }`
      );
      await uploadBytes(storageRef, imagen).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          const docRef = doc(db, "solicitudes", datos.id);
          updateDoc(docRef, {
            fechaModificacion: serverTimestamp(),
            [nombre]: downloadURL,
            ["nombre" + nombre]: imagen?.name,
          })
            .then(() => {
              addLog(
                "ACTUALIZAR solicitudes",
                {
                  id: userInfo.email,
                  [nombre]: downloadURL,
                  ["nombre" + nombre]: imagen?.name,
                },
                userInfo
              );
              setLoad(false);
              cargar();
              Swal.fire({ icon: "success", text: "Archivo Subido" });
            })
            .catch((error) => {
              setLoad(false);
              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (JSON.stringify(error) === "{}"
                    ? error
                    : JSON.stringify(error)),
              });
            });
        });
      });
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        text:
          "Error: " +
          (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
      });
    }
  }

  const uploadPDF = async () => {
    setOpen3(false);
    setLoad(true);
    try {
      const storageRef = ref_storage(
        storage,
        `Solicitud de Crédito/${
          "SOLICITUD - " + datos.codigo + "/Solicitud.pdf"
        }`
      );
      await uploadString(
        storageRef,
        "data:application/pdf;base64," + base64,
        "data_url"
      ).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (downloadURL) => {
          let res = await actualizar(downloadURL);
          if (res) {
            const docRef = doc(db, "solicitudes", datos.id);
            updateDoc(docRef, {
              fechaDocumento: serverTimestamp(),
              fechaModificacion: serverTimestamp(),
              documento: downloadURL,
            })
              .then(() => {
                addLog(
                  "ACTUALIZAR solicitudes",
                  {
                    id: userInfo.email,
                    documento: downloadURL,
                  },
                  userInfo
                );
                cargar();
                setLoad(false);
                Swal.fire({ icon: "success", text: "Archivo Subido" });
              })
              .catch((error) => {
                setLoad(false);
                Swal.fire({
                  icon: "error",
                  text:
                    "Error: " +
                    (JSON.stringify(error) === "{}"
                      ? error
                      : JSON.stringify(error)),
                });
              });
          } else {
            setLoad(false);
            Swal.fire({
              icon: "error",
              text: "No se pudo enviar la información al servidor",
            });
          }
        });
      });
    } catch (error) {
      setLoad(false);
      Swal.fire({
        icon: "error",
        text:
          "Error: " +
          (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
      });
    }
  };

  const [sign, setSign] = useState(null);

  const handleClear = () => {
    if (sign) {
      sign.clear();
    }
  };

  function descargar(datos2) {
    let obj = {};
    if (datos2.solicitud) {
      obj = {
        ...datos2.solicitud,
        ...datos2.datos,
        firmaCliente: datos2.firmaCliente,
        firmaRepresentante: datos2.firmaRepresentante,
      };
    }
    createPdf(formato(obj));
  }

  const download = () => {
    setOpen2(false);
    if (sign) {
      let dataURL = sign.getTrimmedCanvas().toDataURL("image/png");
      setLoad(true);
      if (datos) {
        const docRef = doc(db, "solicitudes", datos.id);
        updateDoc(docRef, {
          firmaCliente: dataURL,
        })
          .then(() => {
            addLog(
              "ACTUALIZAR solicitudes",
              {
                id: userInfo.email,
                firmaCliente: dataURL,
              },
              userInfo
            );
            cargar();
            setLoad(false);
            Swal.fire({ icon: "success", text: "Firma Registrada" });
          })
          .catch((error) => {
            setLoad(false);
            Swal.fire({
              icon: "error",
              text:
                "Error: " +
                (JSON.stringify(error) === "{}"
                  ? error
                  : JSON.stringify(error)),
            });
          });
      } else {
        setLoad(false);
      }
    }
  };

  return (
    <>
      {url === "" ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box spacing={2}>
                <Button
                  component="label"
                  variant="contained"
                  onClick={() => setOpen2(true)}
                  sx={{ margin: "5px" }}
                  color="warning"
                  startIcon={<CloudUploadIcon />}
                >
                  {datos.firmaCliente
                    ? "Volver a Firmar"
                    : "Firmar Digitalmente"}
                </Button>
                {datos.firmaCliente && (
                  <Button
                    component="label"
                    variant="contained"
                    onClick={() => setOpen3(true)}
                    sx={{ margin: "5px" }}
                    disabled={!datos.firmaCliente || base64 === ""}
                    color="success"
                  >
                    Confirmar Documento Y Subir
                  </Button>
                )}
              </Box>
              <br />
              <Paper
                elevation={2}
                sx={{ padding: "20px", backgroundColor: "#eeeeee" }}
              >
                <Paper
                  elevation={4}
                  sx={{
                    padding: "10px",
                    borderColor: "#eeeeee",
                    border: 1,
                  }}
                >
                  <Stack spacing={3} direction={"row"}>
                    <Box>
                      <Typography>
                        <strong>Cédula Frontal </strong>
                      </Typography>
                      <Button
                        component="label"
                        fullWidth
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                      >
                        {datos?.CedulaFrontal
                          ? "Cambiar archivo"
                          : "Cargar Cedula Frontal"}
                        <input
                          hidden
                          type="file"
                          accept=".jpg, .png, application/pdf"
                          onChange={changeImagen}
                        />
                        <br />
                        {datos?.nombreCedulaFrontal ?? ""}
                      </Button>
                      <br />
                      {datos?.CedulaFrontal &&
                        !datos?.nombreCedulaFrontal.includes(".pdf") && (
                          <img
                            alt="frontal"
                            src={datos?.CedulaFrontal}
                            height="100px"
                          />
                        )}
                    </Box>
                    <Box>
                      <Typography>
                        <strong>Cédula Posterior </strong>
                      </Typography>
                      <Button
                        component="label"
                        variant="outlined"
                        fullWidth
                        startIcon={<CloudUploadIcon />}
                      >
                        {datos?.CedulaPosterior
                          ? "Cambiar archivo"
                          : "Cargar Cedula Posterior"}
                        <input
                          hidden
                          type="file"
                          accept=".jpg, .png, application/pdf"
                          onChange={changeImagen2}
                        />
                        <br />
                        {datos?.nombreCedulaPosterior ?? ""}
                      </Button>
                      <br />
                      {datos?.CedulaPosterior &&
                        !datos?.nombreCedulaPosterior.includes(".pdf") && (
                          <img
                            alt="posterior"
                            src={datos?.CedulaPosterior}
                            height="100px"
                          />
                        )}
                    </Box>
                  </Stack>
                  <br />
                  <a>
                    Las imágenes deben tener un tamaño máximo de 5 mb y estar en
                    formato JPG, PNG o PDF
                  </a>
                </Paper>
              </Paper>
            </Grid>
          </Grid>
          <br />

          {base64 && (
            <>
              <a>Si no puede ver el archivo haga click</a>
              <a style={{ color: "red" }} onClick={() => descargar(datos)}>
                <strong> AQUÍ</strong>
              </a>
              <iframe
                title={"PDF"}
                style={{ width: "100%", height: "600px" }}
                src={`data:application/pdf;base64,${base64}`}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="h5">
            <strong>Archivo Enviado</strong>
          </Typography>
          <br />
          <a>Si no puede ver el archivo haga click</a>
          <a style={{ color: "red" }} href={url} download target="_blank">
            <strong> AQUÍ</strong>
          </a>
          <iframe
            title={"PDF"}
            style={{ width: "100%", height: "800px" }}
            src={url}
          />
        </>
      )}

      <Dialog open={open3} onClose={() => setOpen3(false)}>
        <DialogTitle>
          <Typography sx={{ fontSize: "24px" }}>
            <strong>¿Está seguro de enviar el archivo?</strong> <br />
          </Typography>
          <a>No podrá cambiarlo una vez enviado.</a>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => setOpen3(false)}
          >
            CANCELAR
          </Button>
          <Button color="success" variant="contained" onClick={uploadPDF}>
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>

      <Cargando open={load} />

      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setSign("");
          setOpen2(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                <strong>Firme en la siguiente área</strong> <br />
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0,
            }}
          >
            <SignatureCanvas
              backgroundColor="white"
              canvasProps={{ className: "signature" }}
              ref={(data) => {
                setSign(data);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="contained"
            onClick={() => handleClear()}
          >
            Limpiar
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              setSign("");
            }}
          >
            CANCELAR
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => download()}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
