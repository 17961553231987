import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { addLog } from "../../components/utils/Log";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { validarRoles } from "../../components/Navigation/ValidarRoles";

export const HomePage = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  useEffect(() => {
    ver();
  }, []);

  function ver() {
    addLog(
      "VER home",
      {
        mensaje: "HOME",
      },
      userInfo
    );
  }

  return (
    <Box px={3} py={1}>
      <Paper elevation={2} sx={{ padding: "20px" }}>
        <Typography variant="h5">
          <strong>Home</strong>
        </Typography>
        <br />
        <Typography variant="h6">Hola {userInfo && userInfo.nombre}</Typography>
      </Paper>
      <br />

      <Paper elevation={2} sx={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {validarRoles("pedidos", userInfo) && (
            <Grid item xs={12} md={3}>
              <Button href="/pedidos" color="info" fullWidth variant="outlined">
                <Box sx={{ textAlign: "center" }}>
                  <LocalShippingIcon sx={{ fontSize: 40 }} />
                  <br />
                  <strong>Pedidos</strong>
                </Box>
              </Button>
            </Grid>
          )}
          {validarRoles("estados", userInfo) && (
            <Grid item xs={12} md={3}>
              <Button
                href="/estados"
                color="warning"
                fullWidth
                variant="outlined"
              >
                <Box sx={{ textAlign: "center" }}>
                  <AccountBalanceIcon sx={{ fontSize: 40 }} />
                  <br />
                  <strong>Estados de Cuenta</strong>
                </Box>
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};
