import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  ListItemButton,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  createTheme,
  Divider,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import image from "../../assets/logoBlanco.svg";
import { MenuAccount } from "./MenuAccount";
import logo from "../../assets/blanco.svg";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useSelector } from "react-redux";
import { validarRoles } from "./ValidarRoles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export const Nav = (props) => {
  const navigate = useNavigate();
  let theme = createTheme({
    palette: {
      mode: "dark",
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            boxSizing: "border-box",
          },
        },
      },
    },
  });

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [peq, setPeq] = useState(true);

  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     if (window.innerWidth <= 600) {
  //       setPeq(true);
  //     } else {
  //       setPeq(false);
  //     }
  //   };
  //   window.addEventListener("resize", handleWindowResize);
  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, [window.innerWidth]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      margin: 0,
      padding: 0,
      marginLeft: `${240}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  return (
    <>
      {props.isAuth && (
        <>
          <Box pb={5} sx={{ flexGrow: 1 }}>
            <AppBar pb={5} variant="outlined" position="absolute" elevation={0}>
              <Toolbar variant="dense">
                {peq && (
                  <>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>
                    <img
                      src={logo}
                      alt="logo"
                      width={150}
                      onClick={() => navigate("/")}
                      onKeyDown={() => navigate("/")}
                    />
                  </>
                )}

                <Typography sx={{ flexGrow: 1 }}></Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
                  <MenuAccount />
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}>
            <Drawer
              // open={mobileOpen}
              open={!peq ? true : mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                width: 240,
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 240,
                  backgroundColor: "secondary.main",
                  color: "white",
                },
              }}
              anchor="left"
              variant={!peq ? "persistent" : "temporary"}
            >
              <ThemeProvider theme={theme}>
                <Box>
                  <Toolbar
                    style={{ justifyContent: "center", padding: "10px" }}
                  >
                    <Box>
                      <img src={image} alt="agrobimsa" width="100" />
                    </Box>
                  </Toolbar>
                  <Divider style={{ paddingTop: 5 }} variant="middle" />
                  <List dense>
                    <ListItemButton component={RouterLink} to={`/`}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          style: { fontWeight: "bold" },
                        }}
                        primary="Home"
                      />
                    </ListItemButton>

                    {/* {validarRoles("solicitudes", userInfo) && (
                      <ListItemButton component={RouterLink} to={`/solicitud`}>
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                          primary="Solicitud de Crédito"
                        />
                      </ListItemButton>
                    )} */}

                    {validarRoles("pedidos", userInfo) && (
                      <ListItemButton component={RouterLink} to={`/pedidos`}>
                        <ListItemIcon>
                          <LocalShippingIcon />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                          primary="Pedidos"
                        />
                      </ListItemButton>
                    )}

                    {validarRoles("estados", userInfo) && (
                      <ListItemButton component={RouterLink} to={`/estados`}>
                        <ListItemIcon>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                          primary="Estados de Cuenta"
                        />
                      </ListItemButton>
                    )}

                    <br />
                    <Divider style={{ paddingTop: 5 }} variant="middle" />

                    <ListItem>
                      <Typography
                        sx={{ width: "100%", fontWeight: "bold" }}
                        align="center"
                        variant="body2"
                      >
                        {`Agrobimsa - ${new Date().getFullYear()}` + " v0.0.12"}
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </ThemeProvider>
            </Drawer>
          </Box>
          <Box pt={4}></Box>
        </>
      )}

      {!peq ? (
        <Main open={!props.isAuth}>{props.children}</Main>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};
