import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export function addLog(evento, mensaje, userInfo) {
  try{
    const ref = doc(collection(db, "logs"));
    setDoc(ref, {
      evento: evento,
      fecha: serverTimestamp(),
      usuario: userInfo ?? "",
      mensaje: mensaje,
    });
  } catch (error){
    console.error(error)
  }
}
