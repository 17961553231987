import { Box, Button, Grid, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Cargando } from "../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFecha } from "../../components/utils/FiltroFecha";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatoEstado } from "./FormatoEstado";
import { respuesta } from "../../components/utils/Respuesta";
import { addLog } from "../../components/utils/Log";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const Estados = () => {
  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 14)),
    endOfDay(new Date()),
  ]);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [lista, setLista] = useState([]);
  const [load, setLoad] = useState(false);

  // const [codigo, setCodigo] = useState(null);

  const [clientes, setClientes] = useState([]);

  const [tipo, setTipo] = useState("ACTUAL");

  useEffect(() => {
    cargar();
    ver();
    // if (validarRoles("admin", userInfo)) {
    //   if(clientes.length === 0)
    //   cargarClientes();
    // }
  }, []);

  function ver() {
    addLog(
      "VER estado cuenta",
      {
        mensaje: "Estado de Cuenta",
      },
      userInfo
    );
  }

  function cargarClientes() {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", process.env.REACT_APP_SAP);
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    const requestOptions2 = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://monthly-moved-weasel.ngrok-free.app/clientes",
      requestOptions2
    )
      .then(async (response) => {
        return await respuesta(response);
      })
      .then(async (result) => {
        let res1 = JSON.parse(result);
        setClientes(res1);
        addLog(
          "Listar Clientes",
          {
            mensaje: "Listar Clientes",
          },
          userInfo
        );
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
        });
      });
  }

  async function cargar(tipo2, inicio, fin) {
    setLoad(true);
    let filtro = [];
    if (inicio && fin) {
      filtro = [inicio, fin];
    } else {
      filtro = [value[0].toISOString(), value[1].toISOString()];
    }

    let tipo3 = tipo2 ?? tipo;

    //let cod = validarRoles("admin", userInfo) ? codigo?.CardCode : userInfo.CodCliente;
    let cod = userInfo.CodCliente;

    if (cod) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", process.env.REACT_APP_SAP);
      myHeaders.append("ngrok-skip-browser-warning", "asc");
      const requestOptions2 = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      //econsole.log(filtro);
      await fetch(
        "https://monthly-moved-weasel.ngrok-free.app/cabecera/" + cod,
        requestOptions2
      )
        .then(async (response) => {
          return await respuesta(response);
        })
        .then(async (result) => {
          let res1 = JSON.parse(result);
          addLog(
            "Consultar Cabecera",
            {
              mensaje: "Consultar Cabecera " + cod,
            },
            userInfo
          );
          if (res1[0]) {
            let link =
              "https://monthly-moved-weasel.ngrok-free.app/estado/" + cod;
            if (tipo3 !== "ACTUAL") {
              link =
                "https://monthly-moved-weasel.ngrok-free.app/estadoh/" +
                cod +
                ";" +
                filtro[0].split("T")[0] +
                ";" +
                filtro[1].split("T")[0];
            }
            await fetch(link, requestOptions2)
              .then(async (response) => {
                return await respuesta(response);
              })
              .then((result2) => {
                let response = JSON.parse(result2);
                addLog(
                  "Consultar Estado Cuenta",
                  {
                    mensaje: "Consultar Estado Cuenta " + cod,
                  },
                  userInfo
                );
                //econsole.log(response);
                ordenar(
                  {
                    ...res1,
                    consulta: response.hora,
                  },
                  response.list,
                  tipo3,
                  moment(filtro[0]).format("DD/MM/YYYY") +
                    " - " +
                    moment(filtro[1]).format("DD/MM/YYYY"),
                  moment(response.hora).format("DD/MM/YYYY")
                );
              })
              .catch((error) => {
                console.error(error);
                Swal.fire({
                  icon: "error",
                  text:
                    "Error: " +
                    (JSON.stringify(error) === "{}"
                      ? error
                      : JSON.stringify(error)),
                });
              });
          } else {
            setBase64("");
            setLista([]);
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
          });
        });
    } else {
      setBase64("");
      setLista([]);
    }
    setLoad(false);
  }

  function ordenar(res1, res2, tipo3, fechas, corte) {
    cargarPDF({
      ...res1[0],
      consulta: res1.consulta,
      lista: res2,
      tipo3: tipo3,
      fechas: fechas,
      corte: corte,
    });
    setLista({
      ...res1[0],
      consulta: res1.consulta,
      lista: res2,
      tipo3: tipo3,
      fechas: fechas,
      corte: corte,
    });
  }

  const [base64, setBase64] = useState("");

  const cargarPDF = async (datos2) => {
    const response = await createPdf(formatoEstado(datos2), "b64");

    if (!response?.success) {
      Swal.fire({ icon: "error", text: "Error: " + response?.message });
      return;
    }
    setBase64(response?.content ?? "");
  };

  const createPdf = async (docDefinition, output = "print") => {
    return new Promise((resolve, reject) => {
      if (output === "b64") {
        const pdfMakeCreatePdf = pdfMake.createPdf(docDefinition);
        pdfMakeCreatePdf.getBase64((data) => {
          resolve({
            success: true,
            content: data,
            message: "Archivo generado correctamente.",
          });
        });
        return;
      }
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.download("Estado de Cuenta .pdf");
    });
  };

  function descargar() {
    createPdf(formatoEstado(lista));
  }

  function cargar2(inicio, fin) {
    cargar(tipo, inicio, fin);
  }

  return (
    <Box px={3} py={1}>
      <Paper elevation={2} sx={{ padding: "20px" }}>
        <Typography variant="h5">
          <strong>Estado de Cuenta</strong>
        </Typography>
        <br />
        <Grid container spacing={2}>
          {/* {validarRoles("admin", userInfo) &&
            <Grid item xs={12}>
            <Typography>
              <strong>Cliente: </strong>{" "}
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <Autocomplete
                sx={{width: "500px"}}
                options={clientes}
                isOptionEqualToValue={(option, value) =>
                  option?.CardCode === value?.CardCode
                }
                size="small"
                value={codigo ?? null}
                getOptionLabel={(option) => option?.CardCode +" - "+ option?.CardName}
                onChange={(event, newValue) =>
                  setCodigo(newValue ?? null)
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Seleccionar..." />
                )}
              />
              <Button
                color="success"
                disabled={!codigo || codigo?.CardCode.length <= 10}
                variant="contained"
                onClick={() => {
                  cargar();
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          } */}
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>Tipo Solicitud</strong>
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <Select
                fullWidth
                size="small"
                value={tipo}
                onChange={(e) => {
                  setTipo(e.target.value);
                  cargar(
                    e.target.value,
                    value[0].toISOString(),
                    value[1].toISOString()
                  );
                }}
              >
                <MenuItem key={0} value={"ACTUAL"}>
                  ESTADO DE CUENTA
                </MenuItem>
                <MenuItem key={1} value={"HISTORICO"}>
                  ESTADO DE CUENTA HISTÓRICO
                </MenuItem>
              </Select>
              <Button
                color="success"
                disabled={(tipo === "ACTUAL"
                  ? false
                  : isNaN(value[0]) || isNaN(value[1]))
              }
                variant="contained"
                onClick={() => {
                  cargar();
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {tipo !== "ACTUAL" && (
              <FiltroFecha metodo={cargar2} value={value} setValue={setValue} />
            )}
            {base64 && (
              <>
                <a>Si no puede ver el archivo haga click</a>
                <a
                  style={{ color: "red" }}
                  onClick={() => descargar()}
                  onKeyDown={() => descargar()}
                >
                  <strong> AQUÍ</strong>
                </a>
                <iframe
                  title={"PDF"}
                  style={{ width: "100%", height: "600px" }}
                  src={`data:application/pdf;base64,${base64}`}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Cargando open={load} />
      </Paper>
    </Box>
  );
};
