import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import Swal from "sweetalert2";
import { Cargando } from "../../components/utils/Cargando";
import SignatureCanvas from "react-signature-canvas";
import { Tabla } from "../../components/utils/Tabla";
import { DataGrid, esES } from "@mui/x-data-grid";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFecha } from "../../components/utils/FiltroFecha";
import { addLog } from "../../components/utils/Log";

export const Pedidos = () => {
  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 14)),
    endOfDay(new Date()),
  ]);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [lista, setLista] = useState([]);
  const [load, setLoad] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    cargar();
    ver();
  }, []);

  function ver() {
    addLog(
      "VER Pedidos",
      {
        mensaje: "Ver Pedidos",
      },
      userInfo
    );
  }

  async function cargar(inicio, fin) {
    setOpen(false);
    setOpen2(false);
    setLoad(true);
    let filtro = [];
    if (inicio && fin) {
      filtro = [inicio, fin];
    } else {
      filtro = [value[0].toISOString(), value[1].toISOString()];
    }
    try {
      const q = query(
        collection(db, "lote"),
        where("datos.correo", "==", userInfo.email),
        where("fechaCreacion", ">=", new Date(filtro[0])),
        where("fechaCreacion", "<=", new Date(filtro[1]))
      );
      const querySnapshot = await getDocs(q);
      let pedidos = [];
      addLog(
        "Consultar Pedidos",
        {
          mensaje: "Consultar Pedidos",
        },
        userInfo
      );
      querySnapshot.forEach((doc) => {
        
        let valores = doc.data();
        let info = {
          ...valores,
          id: doc.id,
          descripcion: valores?.datos?.descripcion
            ? JSON.parse(valores?.datos?.descripcion)
            : {},
        };
        let nombres = [];
        let cantidades = [];
        if (info.descripcion?.productos) {
          for (const el of info.descripcion.productos) {
            let ind = nombres.indexOf(el.Producto);
            if (ind >= 0) {
              cantidades[ind] = cantidades[ind] + Number(el.Cantidad);
            } else {
              nombres.push(el.Producto);
              cantidades.push(Number(el.Cantidad));
            }
          }
        }
        if (info.datos?.fechaEnvio) {
          let datos2 = info.datos?.fechaEnvio.split(" ")[0].split("-");
          info = {
            ...info,
            despacho: moment(
              datos2[2] +
                "-" +
                datos2[1] +
                "-" +
                datos2[0] +
                " " +
                info.datos?.fechaEnvio.split(" ")[1]
            ).format("YYYY-MM-DD HH:mm"),
          };
        }

        pedidos.push({
          ...info,
          productos: [
            ...nombres.map((val3, ind) => {
              return {
                Producto: val3,
                Cantidad: cantidades[ind],
              };
            }),
          ],
        });
      });

      pedidos.sort(function (a, b) {
        return b.fechaCreacion.seconds - a.fechaCreacion.seconds;
      });
      setLista(pedidos);
    } catch (err) {
      console.error(err);
    }

    setLoad(false);
  }

  const [sign, setSign] = useState(null);

  const handleClear = () => {
    if (sign) {
      sign.clear();
    }
  };

  const download = () => {
    setOpen2(false);
    let dataURL = sign.getTrimmedCanvas().toDataURL("image/png");
    setLoad(true);
    if (id !== "") {
      const docRef = doc(db, "lote", id.id);
      updateDoc(docRef, {
        fechaRecepcion: serverTimestamp(),
        firmaCliente: dataURL,
        estado: "RECIBIDO",
        estado2: "PENDIENTE",
      })
        .then(() => {
          Swal.fire({ icon: "success", text: "Datos actualizados" });
          cargar();
        })
        .catch((error) => {
          setLoad(false);
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
          });
        });
    } else {
      setLoad(false);
    }
  };

  const estados = ["DESPACHADO", "ENVIADO", "ENTREGADO / RECIBIDO"];

  function verificar(campo) {
    if (campo === "ENTREGADO" || campo === "RECIBIDO") {
      return 2;
    } else {
      return estados.indexOf(campo);
    }
  }

  const columns = [
    {
      field: "Ver",
      hideable: false,
      filterable: false,
      headerClassName: "super-app-theme--header",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton
            // component={Link} to={"/AgroTool/lotes/" + params.row.id}
            onClick={() => {
              setItem(params.row);
              setOpen(true);
            }}
          >
            <VisibilityIcon color="warning" />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "datos.codigo",
      headerClassName: "super-app-theme--header",
      headerName: "Pedido",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return params.row.datos?.codigo ?? null;
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.tipo ?? "Incompleto";
      },
      renderCell: (params) => (
        <Chip
          label={params.row.estado}
          color={
            params.row.estado === "RECIBIDO"
              ? "success"
              : params.row.estado === "DESPACHADO"
              ? "info"
              : "warning"
          }
        />
      ),
    },
    {
      field: "Productos",
      headerClassName: "super-app-theme--header",
      headerName: "Productos",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.productos
          ? params.row.productos.reduce(
              (accumulator, currentValue) =>
                accumulator + parseFloat(currentValue.Cantidad),
              0
            ) + " items"
          : "";
      },
    },
    {
      field: "despacho",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha Despacho",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "fechaEnvio",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha Envío",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.fechaEnvio
          ? moment(params.row.fechaEnvio.seconds * 1000).format(
              "YYYY-MM-DD HH:mm"
            )
          : null;
      },
    },
    {
      field: "fechaEntrega",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha Entrega",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.fechaEntrega
          ? moment(params.row.fechaEntrega.seconds * 1000).format(
              "YYYY-MM-DD HH:mm"
            )
          : null;
      },
    },
    {
      field: "fechaRecepcion",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha Recepción",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.fechaRecepcion
          ? moment(params.row.fechaRecepcion.seconds * 1000).format(
              "YYYY-MM-DD HH:mm"
            )
          : null;
      },
    },
  ];

  return (
    <Box px={3} py={1}>
      <Paper elevation={2} sx={{ padding: "20px" }}>
        <Typography variant="h5">
          <strong>Pedidos Registrados</strong>
        </Typography>
        <br />
        <FiltroFecha metodo={cargar} value={value} setValue={setValue} />
        <Box sx={{ overflowX: "hidden", padding: "10px" }}>
          <Box
            sx={{
              height: 500,
              minWidth: 800,
              "& .super-app-theme--header": {
                backgroundColor: "primary.main",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid
              disableColumnMenu
              hideFooterSelectedRowCount
              rows={lista ?? []}
              loading={lista === null}
              columns={columns}
              getRowId={(row) => row.id}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </Box>
        <Cargando open={load} />

        <Dialog
          open={open2}
          maxWidth="lg"
          onClose={() => {
            setOpen2(false);
            setSign("");
            setId("");
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <strong>Firme la recepción de la Guía:</strong> {id.id}
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <SignatureCanvas
                backgroundColor="white"
                canvasProps={{ className: "signature" }}
                ref={(data) => {
                  setSign(data);
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="contained"
              onClick={() => handleClear()}
            >
              Limpiar
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setOpen2(false);
                setSign("");
                setId("");
              }}
            >
              CANCELAR
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={() => download()}
            >
              CONFIRMAR
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          maxWidth="sm"
          onClose={() => {
            setOpen(false);
            setItem(null);
          }}
        >
          <DialogContent>
            <Paper
              elevation={6}
              sx={{
                padding: "20px",
                borderRadius: "25px",
                borderColor: "#eeeeee",
                border: 1,
              }}
            >
              <Typography align="center" sx={{ fontSize: "18px" }}>
                <strong>Pedido: </strong>
                {item?.datos.codigo}
              </Typography>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ overflowX: "scroll" }}>
                  <Stepper
                    activeStep={verificar(item?.estado)}
                    alternativeLabel
                  >
                    {estados.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "green",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "info",
                          },
                        }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  {item?.productos ? (
                    <Tabla
                      filas={[
                        ...item.productos
                          .filter((val3) => val3.Cantidad > 0)
                          .map((val2) => {
                            return {
                              Producto: val2.Producto,
                              Cantidad: val2.Cantidad + " unidades",
                            };
                          }),
                      ]}
                      columnas={["Producto", "Cantidad"]}
                    />
                  ) : (
                    ""
                  )}
                  <br />
                </Grid>

                {item?.datos?.fechaEnvio && (
                  <Grid item xs={12}>
                    <strong>Fecha Despacho:</strong> {item?.despacho ?? ""}
                  </Grid>
                )}
                {item?.fechaEnvio && (
                  <Grid item xs={12}>
                    <strong>Fecha Envío:</strong>{" "}
                    {item?.fechaEnvio &&
                      moment(item?.fechaEnvio.seconds * 1000).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                  </Grid>
                )}
                {item?.fechaEntrega && (
                  <Grid item xs={12}>
                    <strong>Fecha Entrega:</strong>{" "}
                    {item?.fechaEntrega &&
                      moment(item?.fechaEntrega.seconds * 1000).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                  </Grid>
                )}
                {item?.fechaRecepcion && (
                  <Grid item xs={12}>
                    <strong>Fecha Recepción:</strong>{" "}
                    {item?.fechaRecepcion &&
                      moment(item?.fechaRecepcion.seconds * 1000).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                  </Grid>
                )}
                <br />

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {item?.estado === "ENVIADO" ? (
                    <Button
                      sx={{ width: "80%" }}
                      onClick={() => {
                        setId(item);
                        setOpen2(true);
                      }}
                      color="info"
                      size="small"
                      fullWidth
                      variant="contained"
                    >
                      CONFIRMAR RECEPCION
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setOpen(false);
                setItem(null);
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};
