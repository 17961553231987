import moment from 'moment';
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

moment.locale("es", {
    months:
      "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
        "_"
      ),
    monthsShort:
    "Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

export const Title = () => {
    const [title, setTitle] = React.useState('')
    const { pathname } = useLocation()

    React.useEffect(() => {
        if (pathname?.includes("/")) {
            setTitle(pathname.split("/")[1])
        } else {
            setTitle("")
        }
    }, [pathname])

    return (
        <Helmet >
            <title>{title.length > 0 ? title.toUpperCase()+ " - AgroCliente" : "HOME  - AgroCliente"}
            </title>
        </Helmet>
    )
}
