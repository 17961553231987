import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { validarRoles } from "../components/Navigation/ValidarRoles";

export default function PrivateRoute({ isAuth, rol }) {
  const userAuth = useSelector((state) => state.userAuth.userInfo);
  const location = useLocation();

  return isAuth && userAuth.cambiado && validarRoles(rol, userAuth) ? (
    <Outlet />
  ) : isAuth && !userAuth.cambiado ? (
    <Navigate to="/password" state={{ from: location }} replace />
  ) : (
    <>
      {userAuth?.email ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
}
