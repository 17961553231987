import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import logo from "../../assets/logoBlanco.svg";
import logo2 from "../../assets/logo.svg";
import fondo from "../../assets/fondo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addLog } from "../../components/utils/Log";
import { logout } from "../../redux/actions/AuthAction";

export const PassWord = () => {
  const navigate = useNavigate();
  const [objeto, setObjeto] = useState({});
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.userAuth.userInfo);
  useEffect(() => {
    if (userAuth?.cambiado) {
      navigate("/");
    }
  }, [userAuth, navigate]);

  const [load, setLoad] = useState(false);
  const defaultTheme = createTheme();

  function limpiar() {
    setObjeto({});
  }

  function actualizar() {
    setLoad(true);
    const functions = getFunctions();
    const updateUser = httpsCallable(functions, "updateUser");
    updateUser({
      email: userAuth.email,
      password: objeto.contra,
      uid: userAuth.id,
    })
      .then((result) => {
        setLoad(false);
        if (result.data.error) {
          addLog(
            "CAMBIO contraseña fallido",
            {
              uid: userAuth.id,
            },
            userAuth
          );
          Swal.fire({
            icon: "error",
            text: "Error: " + result.data.error.errorInfo,
          });
        } else {
          addLog(
            "CAMBIO contraseña exitoso",
            {
              uid: userAuth.id,
              contraseña: objeto.contra,
            },
            userAuth
          );
          limpiar();
          addLog(
            "LOGOUT usuario",
            {
              mensaje: "Salir",
            },
            userAuth
          );
          dispatch(logout());
          Swal.fire({
            icon: "success",
            text: "Credenciales actualizadas, ingrese nuevamente",
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            backgroundImage: `url(${fondo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid
              item
              px={6}
              py={2}
              sx={{
                backgroundColor: "rgba(0,0,0,.4)",
                color: "#fffff",
                // maxWidth: "500px",
                display: { xs: "none", sm: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
                borderRadius: "25px 0px 0px 25px",
              }}
            >
              <img src={logo} alt="logo" width={200} />
            </Grid>
            <Grid
              item
              px={4}
              xs={12}
              sm={12}
              md={"auto"}
              py={2}
              sx={{
                backgroundColor: "white",
                color: "#fffff",
                height: { xs: "100vh", sm: "100vh", md: "400px" },
                borderRadius: { xs: "0px", sm: "0px", md: "0px 25px 25px 0px" },
                display: { xs: "block", sm: "block", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                px={2}
                py={2}
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={logo2} alt="logo" width={300} />
              </Box>
              <Stack direction={"column"}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  <strong>Cambiar Contraseña</strong>
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ mt: 1 }}>
                    <strong>Correo:</strong> {userAuth.email}
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    <strong>Nueva Contraseña</strong> (Min: 6 caracteres)
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={objeto.contra ?? ""}
                    autoComplete="off"
                    type="password"
                    onChange={(e) =>
                      setObjeto({ ...objeto, contra: e.target.value })
                    }
                    error={objeto.contra?.length < 6}
                  />
                  <Typography sx={{ mt: 1 }}>
                    <strong>Confirmar Nueva Contraseña</strong>
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    value={objeto.contra2 ?? ""}
                    autoComplete="off"
                    onChange={(e) =>
                      setObjeto({ ...objeto, contra2: e.target.value })
                    }
                    error={
                      objeto.contra2?.length < 6 ||
                      objeto.contra2 !== objeto.contra
                    }
                  />

                  <Button
                    fullWidth
                    disabled={
                      objeto.contra2?.length < 6 ||
                      objeto.contra?.length < 6 ||
                      typeof objeto.contra2 !== "string" ||
                      typeof objeto.contra !== "string" ||
                      objeto.contra2 !== objeto.contra
                    }
                    onClick={() => actualizar()}
                    variant="contained"
                    sx={{ mt: 2, mb: 1 }}
                  >
                    Confirmar
                  </Button>
                  <br />
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    Salir
                  </Button>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ mt: 3 }}
                  >
                    <strong>
                      {"Copyright © "}
                      <a
                        color="inherit"
                        href="https://agrobimsa.com/home/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Agrobimsa
                      </a>
                      {" " + new Date().getFullYear() + " v0.0.12"}
                    </strong>
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Cargando open={load} />
        </Box>
      </ThemeProvider>
    </div>
  );
};
