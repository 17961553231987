import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

export const Tabla = ({ filas, columnas }) => {
    return (
        <TableContainer component={Paper}>
            <Table size="small" >
                <TableHead sx={{ backgroundColor: "#eeeeee" }}>
                    <TableRow >
                        {columnas.map((val) => (
                            <TableCell key={val}><strong>{val}</strong></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filas.map((val, index) => (
                        <TableRow key={index}>
                            {columnas.map((val2, index2) => (
                                <TableCell key={index2}>{val[val2]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
