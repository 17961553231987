import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  ListItemIcon,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  Button,
  DialogActions,
  TextField,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthAction";
import { addLog } from "../utils/Log";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Cargando } from "../utils/Cargando";

export const MenuAccount = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const [ventana, setVentana] = useState(false);
  const [objeto, setObjeto] = useState({});
  const [load, setLoad] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutHandler = () => {
    addLog(
      "LOGOUT usuario",
      {
        mensaje: "Salir",
      },
      userInfo
    );
    dispatch(logout());
  };

  function limpiar() {
    setVentana(false);
    setObjeto({});
  }

  function actualizar() {
    setLoad(true);
    setVentana(false);
    const functions = getFunctions();
    const updateUser = httpsCallable(functions, "updateUser");
    updateUser({
      email: userInfo.email,
      password: objeto.contra,
      uid: userInfo.id,
    })
      .then((result) => {
        setLoad(false);
        if (result.data.error) {
          addLog(
            "CAMBIO contraseña fallido",
            {
              uid: userInfo.id,
            },
            userInfo
          );
          Swal.fire({
            icon: "error",
            text: "Error: " + result.data.error.errorInfo,
          });
        } else {
          addLog(
            "CAMBIO contraseña exitoso",
            {
              uid: userInfo.id,
              contraseña: objeto.contra,
            },
            userInfo
          );
          limpiar();
          addLog(
            "LOGOUT usuario",
            {
              mensaje: "Salir",
            },
            userInfo
          );
          dispatch(logout());
          Swal.fire({ icon: "success", text: "Credenciales actualizadas, ingrese nuevamente" });
        }
      })
      .catch((error) => {
        setLoad(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (JSON.stringify(error) === "{}" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar />
      </IconButton>
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "10px" }}
        elevation={1}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar />
          </ListItemIcon>
          <Box pl={2}>
            {userInfo && (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  {userInfo.nombre}
                </Typography>
                <Typography
                  style={{
                    width: 200,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {" "}
                  {userInfo.email}
                </Typography>
              </>
            )}
          </Box>
        </MenuItem>
        <MenuItem onClick={() => setVentana(true)}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          Cambiar Contraseña
        </MenuItem>
        <MenuItem onClick={logoutHandler}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Salir
        </MenuItem>
      </Menu>

      <Dialog
        open={ventana}
        fullWidth
        maxWidth="xs"
        onClose={() => {
          limpiar();
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "24px" }}>
                <strong>Cambio de Contraseña</strong>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box px={1}>
            <Typography>
              <strong>Nueva Contraseña *</strong> (Min: 6 caracteres)
            </Typography>
            <TextField
              fullWidth
              size="small"
              type="password"
              value={objeto.contra ?? ""}
              autoComplete="off"
              onChange={(e) => setObjeto({ ...objeto, contra: e.target.value })}
              error={objeto.contra?.length < 6}
            />
          </Box>
          <Box px={1}>
            <Typography>
              <strong>Confirmar Nueva Contraseña *</strong>
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              size="small"
              type="password"
              value={objeto.contra2 ?? ""}
              onChange={(e) =>
                setObjeto({ ...objeto, contra2: e.target.value })
              }
              error={
                objeto.contra2?.length < 6 || objeto.contra2 !== objeto.contra
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              limpiar();
            }}
          >
            CANCELAR
          </Button>
          <Button
            disabled={
              objeto.contra2?.length < 6 ||
              objeto.contra?.length < 6 ||
              typeof objeto.contra2 !== "string" ||
              typeof objeto.contra !== "string" ||
              objeto.contra2 !== objeto.contra
            }
            color="success"
            variant="contained"
            onClick={() => actualizar()}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>

      <Cargando open={load} />
    </>
  );
};
