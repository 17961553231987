import esES from "rsuite/locales/es_ES";
import React from "react";
import { CustomProvider, DateRangePicker } from "rsuite";
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import { Box } from "@mui/material";

export const FiltroFecha = ({ metodo, value, setValue }) => {
  return (
    <Box py={1}>
      <CustomProvider locale={esES}>
        <strong>Filtro: </strong>
        dd/mm/yyyy
        <br />
        <DateRangePicker
          placement="bottomStart"
          character=" – "
          ranges={[
            {
              label: "Hoy",
              value: [startOfDay(new Date()), endOfDay(new Date())],
              placement: "left",
            },
            {
              label: "Última semana",
              value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
              placement: "left",
            },
            {
              label: "Últimos 15 días",
              value: [
                startOfDay(subDays(new Date(), 14)),
                endOfDay(new Date()),
              ],
              placement: "left",
            },
            {
              label: "Mes actual",
              value: [
                startOfDay(startOfMonth(new Date())),
                endOfDay(new Date()),
              ],
              placement: "left",
            },
            {
              label: "Año actual",
              value: [
                startOfDay(startOfYear(new Date())),
                endOfDay(new Date()),
              ],
              placement: "left",
            },
            {
              label: "Todo",
              value: [
                startOfDay(startOfYear(new Date()).setFullYear(2021)),
                endOfDay(new Date()),
              ],
              placement: "left",
            },
          ]}
          value={value ?? new Date()}
          onChange={(e) => {
            let fechas = [startOfDay(e[0]), endOfDay(e[1])];
            if (fechas)
              if (setValue) {
                setValue(fechas);
              }
            if (
              metodo &&
              fechas.length == 2 &&
              isNaN(fechas[0]) &&
              isNaN(fechas[1])
            ) {
              metodo(fechas[0].toISOString(), fechas[1].toISOString());
            }
          }}
          cleanable={false}
          showOneCalendar
          format="dd/MM/yyyy"
        />
      </CustomProvider>
    </Box>
  );
};
