import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PublicRoute, PublicRoute2, PublicRoute3 } from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { CargandoPage } from "../components/utils/Cargando";
import { LoginPage } from "../pages/Login/LoginPage";
import { HomePage } from "../pages/Home/HomePage";
import { db, auth } from "../firebaseConfig";
import { Nav } from "../components/Navigation/Nav";
import Unauthorized from "../components/Unauthorized";
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { Title } from "./Title";
import { SolicitudCredito } from "../pages/Solicitud/SolicitudCredito";
import { Pedidos } from "../pages/Envios/Pedidos";
import { addLog } from "../components/utils/Log";
import { PassWord } from "../pages/Login/Password";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { Estados } from "../pages/Estados/Estados";

export const AppRouters = () => {
  const [checking, setChecking] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const dispatch = useDispatch();
  const mode = "light";

  const [cambiado, setCambiado] = useState(false);

  const [ver, setVer] = useState(true);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "version", "agroclientes"), (doc) => {
      let info = doc.data();
      if (info.version === "v0.0.12") {
        setVer(true);
      } else {
        setVer(false);
      }
    });

    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      //econsole.log("cambio");
      if (userAuth) {
        const docRef = doc(db, "usuarios", userAuth.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let info = { ...docSnap.data(), id: userAuth.uid };
          addLog(
            "LOGIN existoso",
            {
              id: userAuth.uid,
            },
            info
          );
          dispatch({
            type: "AUTH_ADD_ITEMS",
            payload: { ...info, contra: "" },
          });
          setCambiado(info.cambiado);
          setChecking(false);
          //econsole.log("Usuario logeado");
          //econsole.log("cambiado", info.cambiado);
          setIsAuth(true);
        } else {
          addLog(
            "LOGIN fallido",
            {
              id: userAuth.uid,
            },
            null
          );
          dispatch({
            type: "GET_CLIENT_FAIL",
            payload: "Cliente no encontrado",
          });
          //econsole.log("No existe el usuario");
          setChecking(false);
        }
      } else {
        //econsole.log("No esta logeado");
        setChecking(false);
        setIsAuth(false);
      }
    });
    // limpiar()
    return () => {
      //econsole.log("cerrar");
      unsubscribe();
      unsub();
    };
  }, [dispatch]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: "#eeeeee",
          },
          primary: {
            main: "#008CCF",
          },
          secondary: {
            main: "#62AB68",
          },
          mode,
        },
      }),
    [mode]
  );

  if (checking) {
    return <CargandoPage />;
  }

  async function limpiar(){
    const q = query(
      collection(db, "logs"),
      where("usuario.email", "==", "rcedillo@agrobimsa.com"),
    );
    const querySnapshot = await getDocs(q);
    // addLog(
    //   "Consultar Pedidos",
    //   {
    //     mensaje: "Consultar Pedidos",
    //   },
    //   userInfo
    // );
    
    // querySnapshot.forEach(async (doc2) => {
    //   console.log("a")
    //   await deleteDoc(doc(db, "logs", doc2.id));
    // })
    // console.log("FIN")
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Nav isAuth={isAuth && cambiado}>
          <Title />
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<HomePage />} />
            </Route>
            <Route element={<PublicRoute2 />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<PublicRoute3 />}>
              <Route path="/password" element={<PassWord />} />
            </Route>

            <Route element={<PrivateRoute isAuth={isAuth} rol={"pedidos"} />}>
              <Route path="/pedidos" element={<Pedidos />} />
            </Route>
            <Route element={<PrivateRoute isAuth={isAuth} rol={"estados"} />}>
              <Route path="/estados" element={<Estados />} />
            </Route>
            <Route
              element={<PrivateRoute isAuth={isAuth} rol={"solicitudes"} />}
            >
              {/* <Route
                path="/solicitud"
                element={<SolicitudCredito />}
              /> */}
            </Route>

            {/* <Route
              path="/login"
              element={<Unauthorized />}
            /> */}
            <Route
              path="/*"
              element={<Navigate to={isAuth ? "/" : "/login"} />}
            />
            <Route
              path="/*"
              element={<Navigate to={isAuth ? "/" : "/login"} />}
            />
          </Routes>
        </Nav>
      </ThemeProvider>
      <Dialog open={!ver} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography
            style={{
              fontSize: "20px",
            }}
          >
            <strong>Aviso de versión</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            style={{
              alignItems: "center",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            <Typography align="justify">
              La versión del sistema no es actual, refresque la ventana para
              actualizar o no hay conexión con el servidor
            </Typography>
          </Stack>
          <Box display={"flex"} justifyContent="center">
            <SyncProblemIcon color="warning" sx={{ fontSize: "80px" }} />
          </Box>
        </DialogContent>
      </Dialog>
    </BrowserRouter>
  );
};
