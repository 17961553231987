import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
export function PublicRoute() {
  const userAuth = useSelector((state) => state.userAuth.userInfo);
  const location = useLocation();

  return userAuth?.email && userAuth?.cambiado ? (
    <Outlet />
  ) : userAuth?.email && !userAuth?.cambiado ? (
    <Navigate to="/password" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export function PublicRoute2() {
  const userAuth = useSelector((state) => state.userAuth.userInfo);
  const location = useLocation();
  return !userAuth?.email ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export function PublicRoute3() {
  const userAuth = useSelector((state) => state.userAuth.userInfo);
  const location = useLocation();

  return userAuth?.email ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
