export async function respuesta(response) {
  if (response.ok) {
    return response.text();
  } else {
    if ((await response.clone().text()) === "" && response.status === 403) {
      throw "No tienes los permisos necesarios.";
    }
    let mensaje = await response.json();
    throw mensaje;
  }
}
